<template>

<div>

  <contact-entry :contactSection="0" />

  <contacts :contactSection="0"></contacts>

</div>

</template>

<script>
import Contacts from '@/components/Contacts';
import ContactEntry from '@/components/ContactEntry';

export default {
  name: 'ContactsList',
  components: {
    Contacts,
    ContactEntry,
  },
};
</script>

<style scoped>

</style>
