<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="contactSection === 0" :title-value="'Contacts'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-address-book</v-icon>
        <v-toolbar-title class="pl-2">Contacts</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="contactSection !== 3" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <!--Clear Filter Button -->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Provider name, only shows on full contact list-->
                <v-col cols="12" sm="12" v-if="contactSection !== 1">
                  <v-text-field
                    v-model="d_providerName"
                    label="Provider Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--First name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_nameFirst"
                    label="First Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Last name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_nameLast"
                    label="Last Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.valueListItems[24]"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_name"
                    item-value="d_name"
                    v-model="d_type"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.contactStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <export-excel
        :section="21"
        :enabled="contactsStore.contacts.data && contactsStore.contacts.data.length > 0"
      />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="contactsStore.contacts.data && contactsStore.contacts.data.length > 0"
      :showTotal="true"
      :currentPage="contactsStore.contacts.current_page"
      :lastPage="contactsStore.contacts.last_page"
      :total="contactsStore.contacts.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="contactsStore.contacts.data && contactsStore.contacts.data.length > 0"
        :list-data="contactsStore.contacts.data"
        :title="'Contacts'"
        :section="21"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";
import ExportExcel from "@/components/ExportExcel";

const { mapFields } = createHelpers({
  getterType: "contactsStore/getFilterField",
  mutationType: "contactsStore/updateFilterField"
});

export default {
  name: "Contacts",
  components: {
    TitleBar,
    Pagination,
    ListBasic,
    ExportExcel
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      contactsStore: state => state.contactsStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_providerID",
      "d_providerName",
      "d_nameFirst",
      "d_nameLast",
      "d_type",
      "f_status"
    ])
  },
  props: {
    /* All List = 0, Provider = 1 */
    contactSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      if (this.contactSection !== 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* Provider section */
      if (this.contactSection === 1) {
        // await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
      } else {
      /* All list */
        this.fk_providerID = "";
      }

      const filters = this.$store.getters["contactsStore/getFilters"];
      await this.$store.dispatch("contactsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      const data = {
        id: [12, 15, 24, 32, 33, 37]
      };
      await this.$store.dispatch("valueListsStore/items", data);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;

      this.initialize();
    },

    clearFilterColumns() {
      this.d_providerName = "";
      this.d_nameFirst = "";
      this.d_nameLast = "";
      this.d_type = "";
      this.f_status = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.contactSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("contactsStore/clearContacts");
      }
      this.section = this.contactSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_providerName ||
        this.d_nameFirst ||
        this.d_nameLast ||
        this.d_type ||
        this.f_status ||
        this.f_status === 0
      ) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.loadValueLists();
      await this.$store.dispatch("contactsStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
